html {
  height: -webkit-fill-available;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  /* mobile viewport bug fix */
  height: -webkit-fill-available;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100vh;
  /* mobile viewport bug fix */
  height: -webkit-fill-available;
}

@media print  {
  @page {
    margin: 0;
    size: A4;
  }
  body, html, #root  {
    margin: 0 auto;
    width: 210mm;
    height: 297mm;
  }
  * { overflow: visible !important; }
}

input::-webkit-date-and-time-value {
  text-align: left;
}

* {
  -webkit-print-color-adjust: exact !important;   /* Chrome, Safari, Edge */
  color-adjust: exact !important;                 /*Firefox*/
}

.pointer{
  font-weight: 500;
  cursor: pointer;
}